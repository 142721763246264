import React, { useState, useEffect } from 'react';
import './buildings.css'; // Import your CSS file for buildings styling

const Buildings = () => {
    const [buildingsByNeighborhood, setBuildingsByNeighborhood] = useState({});
    const [others, setOthers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        // localhost
        // fetch('http://localhost:8080/public/getLondonBuildingsForCityRelay')

        //LIVE PRODUCTION
        fetch('https://driveportal.cityrelaysolutions.com:443/public/getLondonBuildingsForCityRelay')
            .then(response => response.json())
            .then(data => {
                const filteredBuildings = data.list.filter(building => building.city.id === 7917);
                const firstXBuildings = filteredBuildings.slice(0, 99999);

                // console.log('firstXBuildings=', firstXBuildings);

                const classifyBuildingsByNeighborhood = (buildings) => {
                    const classified = buildings.reduce((acc, building) => {
                        const neighborhood = building.neighborhood;
                        if (neighborhood) { // Check if neighborhood exists
                            const neighborhoodName = neighborhood.name;

                            if (!acc.byNeighborhood[neighborhoodName]) {
                                acc.byNeighborhood[neighborhoodName] = [];
                            }

                            acc.byNeighborhood[neighborhoodName].push(building);
                        } else {
                            acc.others.push(building);
                        }

                        return acc;
                    }, { byNeighborhood: {}, others: [] });

                    return classified;
                };

                const { byNeighborhood, others } = classifyBuildingsByNeighborhood(firstXBuildings);
                setBuildingsByNeighborhood(byNeighborhood);

                // console.log('byNeighborhood=', byNeighborhood);

                setOthers(others);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching buildings:', error);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <section id="buildings-section" className="my-20 md:my-36">
                <div className="container">
                    <p className='alert-msg'>Loading buildings...</p>
                </div>
            </section>
        );
    }

    if (Object.keys(buildingsByNeighborhood).length === 0 && others.length === 0) {
        return (
            <section id="buildings-section" className="my-20 md:my-36">
                <div className="container">
                    <p className='alert-msg'>No buildings available.</p>
                </div>
            </section>
        );
    }

    const sortedNeighborhoods = Object.keys(buildingsByNeighborhood).sort();

    return (
        <section id="buildings-section" className="my-20 md:my-36">
            <div className="container">
                {sortedNeighborhoods.map(neighborhood => (
                    <div key={neighborhood}>
                        <div className='area-icon-name'>
                            <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.6" d="M7 -0.00732422C3.13 -0.00732422 0 3.13442 0 7.01893C0 12.2886 7 20.0677 7 20.0677C7 20.0677 14 12.2886 14 7.01893C14 3.13442 10.87 -0.00732422 7 -0.00732422ZM7 9.52831C5.62 9.52831 4.5 8.40411 4.5 7.01893C4.5 5.63375 5.62 4.50955 7 4.50955C8.38 4.50955 9.5 5.63375 9.5 7.01893C9.5 8.40411 8.38 9.52831 7 9.52831Z" fill="#12203A" />
                            </svg>
                            <h2 className='area-name'>{neighborhood}</h2>
                        </div>
                        <div className="buildings-list">
                            {buildingsByNeighborhood[neighborhood].filter(item => item).map(item => (
                                <div key={item.id} className="item">
                                    <div className="responsive-img-wrapper">
                                        <img
                                            src={item.pictureUrl}
                                            alt={item.marketing_name && item.marketing_name.trim() ? item.marketing_name : item.name}
                                            className="responsive-img"
                                        />
                                    </div>
                                    <div className="name">
                                        {item.marketing_name && item.marketing_name.trim() ? item.marketing_name : item.name}
                                    </div>
                                    {/* <div className="code">Code: {item.code}</div> */}
                                    {/* <div className="address">{item.address}</div> */}
                                    <div className="count">{item.countOfFlats} apartments</div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

                {/* OTHERS (BUILDINGS WHO HAVE NO NEIGHBORHOODS) */}
                {others.length > 0 && (
                    <div>
                        <div className='area-icon-name'>
                            <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.6" d="M7 -0.00732422C3.13 -0.00732422 0 3.13442 0 7.01893C0 12.2886 7 20.0677 7 20.0677C7 20.0677 14 12.2886 14 7.01893C14 3.13442 10.87 -0.00732422 7 -0.00732422ZM7 9.52831C5.62 9.52831 4.5 8.40411 4.5 7.01893C4.5 5.63375 5.62 4.50955 7 4.50955C8.38 4.50955 9.5 5.63375 9.5 7.01893C9.5 8.40411 8.38 9.52831 7 9.52831Z" fill="#12203A" />
                            </svg>
                            <h2 className='area-name'>Others</h2>
                        </div>
                        <div className="buildings-list">
                            {others.map(item => (
                                <div key={item.id} className="item">
                                    <div className="responsive-img-wrapper">
                                        <img
                                            src={item.pictureUrl}
                                            alt={item.marketing_name && item.marketing_name.trim() ? item.marketing_name : item.name}
                                            className="responsive-img"
                                        />
                                    </div>
                                    <div className="name">
                                        {item.marketing_name && item.marketing_name.trim() ? item.marketing_name : item.name}
                                    </div>
                                    {/* <div className="code">Code: {item.code}</div> */}
                                    {/* <div className="address">{item.address}</div> */}
                                    <div className="count">{item.countOfFlats} apartments</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Buildings;
